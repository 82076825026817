.footer_banners {
  max-width: 100px;
  width: 100%;
  height: auto;
  margin: 0 0 30px auto;
}
.footer_bannerDesktop {
  width: 100%;
  height: auto;
  margin: 0;
  font: inherit;
}
.footer_bannerMob {
  display: none;
  width: 100%;
  height: auto;
  margin: 0;
  font: inherit;
}

@media screen and (max-width: 1060px) {
  .footer_banners {
    max-width: none;
  }
}

@media screen and (max-width: 980px) {
  .footer_banners {
    margin: -10px 0 30px 0;
  }
  .footer_bannerDesktop {
    display: none;
  }
  .footer_bannerMob {
    display: block;
  }
}
